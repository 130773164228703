export enum WidgetType {
  LEAFLET_WITH_BANNER_SLIDER = 'LeafletWithBannerSlider',
  THREE_LEAFLET_WITH_BANNER_SLIDER = 'ThreeLeafletWithBannerSlider',
  BANNER_LIST_TWO_COLUMNS = 'BannerListTwoColumns',
  BANNER_LIST_FOUR_COLUMNS = 'BannerListFourColumns',
  BANNER_CAROUSEL_FOUR_COLUMNS = 'BannerCarouselFourColumns',
  PRODUCT_CAROUSEL = 'ProductCarousel',
  BANNER_SINGLE = 'BannerSingle',
  BANNER_LIST_NO_SPACE = 'BannerListNoSpace',
  CUSTOM_HTML = 'CustomHtml', // should probably be "CustomHTML"
  BANNER_LIST_AUTO = 'BannerListAuto',
  PRODUCT_GROUP_LIST = 'ProductGroupList',
  PRODUCT_GROUP_LIST_TAB = 'ProductGroupListTabs',
  PRODUCT_GROUP_GRID_LIST = 'ProductGroupListGrid',
  GRID_BANNERS_WITH_DESCRIPTION = 'GridBannersWithDescription',
  BANNER_LIST = 'BannerList',
  CATEGORY_TWO_COLUMNS_PROMO = 'CategoryTwoColumnsPromo',
  CATEGORY_FULL_WIDTH_PROMO = 'CategoryFullWidthPromo',
  VIDEO_WITH_PRODUCTS_SLIDER = 'VideoWithProductsSlider',
  CONTACT_BOXES = 'ContactBoxes',
  /* NEW widgets */
  TWO_TILE_BANNER = 'TwoTileBanner',
  FOUR_TILE_BANNER = 'FourTileBanner',
  BANNER = 'Banner',
  BANNER_NEW = 'BannerNew',
  SLIDER_WITH_PRODUCT_GROUPS = 'SlideWithProductGroups',
  CONTEST = 'Contest',
}
