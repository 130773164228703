import { Expose, Type } from 'class-transformer';
import File from '~/models/file';
import 'reflect-metadata';

export default class Banner {
  @Expose()
  id?: number;

  @Expose()
  title!: string;

  @Expose()
  alt!: string;

  @Expose()
  slug!: string;

  @Type(() => File)
  file!: File;

  @Expose({ name: 'redirect_url' })
  redirectUrl?: string | null;

  @Expose()
  label?: string;

  @Expose()
  date?: string;

  @Expose({ name: 'date_from' })
  dateFrom?: string | null;

  @Expose({ name: 'date_to' })
  dateTo?: string | null;

  @Expose({ name: 'context' })
  context?: "mobile" | "tablet" | "mobile_and_desktop" | "desktop";

  @Expose({ name: 'active_from' })
  activeFrom?: string | null;

  @Expose({ name: 'active_to' })
  activeTo?: string | null;

  @Expose({ name: 'order' })
  order?: number;
}
